import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.sass';
import { Link } from 'gatsby';

const BlogItem = props => (
  <div className={styles.blogItem}>
    <Link to={props.path}>
      <div className={styles.blogItemImage}>
        <img src={props.coverImage} alt="" />
      </div>
      <div className={styles.blogItemDescription}>
        <div>
          <h2>{props.title}</h2>
          <p>{props.content}</p>
        </div>
        <small>
          <i />
          {props.date}
        </small>
      </div>
    </Link>
  </div>
);

BlogItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  coverImage: PropTypes.any,
  date: PropTypes.any
};

export default BlogItem;
