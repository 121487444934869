import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from './_layout';
import { addLocaleData } from 'react-intl';

import messages from '../../data/messages/ru';
import ru from 'react-intl/locale-data/ru';
import 'intl/locale-data/jsonp/ru';

addLocaleData(ru);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            languages {
              defaultLangKey
              langs
            }
          }
        }
      }
    `}
    render={data => <Layout {...props} data={data} i18nMessages={messages} />}
  />
);
