module.exports = {
  'main.brandName': 'HERO SNATCH',
  'main.youCanDoIt': 'Новый дом функционального фитнеса',
  'main.trackIt': 'Атлеты ведующие учет своих тренировок прогрессируют на 73% быстрее',
  'main.tryIt': 'Создать Аккаунт',
  'metric.results': 'Добавлено результатов',
  'metric.wods': 'Создано WOD',
  'metric.modality': 'Пользователей',
  'features.tracking.title': 'Помните последний результат?',
  'features.tracking.body': 'Мы храним Ваш прогресс за Вас',
  'features.modality.title': 'Сделайте прогресс прозрачным',
  'features.modality.body': 'Получите визуальную картину прогресса',
  'features.scheme.title': 'Мы Сообщество',
  'features.scheme.body': 'Атлеты и тренеры которые хотят каждый день двигаться вперед',
  'joinus.title': 'Становитесь частью нашей семьи!',
  'compatibility.title': `Приложение работает
  на всех устройсвах и
  браузерах`,
  'compatibility.text1':
    'HeroSnatch работает на всех браузерах и устройствах.  Приложение дает вам представление о процессе Ваших функциональных тренировок, предоставляя супер точную информацию.',
  'compatibility.text2':
    ' Приложение позволяет Вам создавать воркауты, отслеживать воркауты Ваших друзей, соревноваться друг с другом, видеть свою статистику, проводите ли вы больше времени на конкретном типе упражнений вместо того, чтобы сосредоточиться на общем уровне подготовки и других замечательных вещах.',
  'compatibility.text3': 'Становитесь частью нашей семьи!',
  'compatibility.ios': 'Загрузить на ios',
  'compatibility.android': 'Загрузить на android',
  'news.title': 'Новости',
  'news.cardTitle': 'HeroSnatch в жизни',
  'news.cardContent1': `Это как борьба с тенью - когда ты знаешь свой предыдущий результат, ты соперничаешь с самим собой и стараешься улучшить результат любой ценой. Невероятный заряд мотивации для каждой тренировки!`,
  'news.cardContent2': `Результат есть, когда ты отслеживаешь и контролируешь его. Благодаря HeroSnatch я добиваюсь целей и вижу прогресс в удобной форме. Приятно видеть, как график моей силы и выносливости растёт вверх. Ещё приятнее – делиться результатами с друзьям :)`,
  'news.cardContent3': `База тренировок помогает всегда легко найти необходимый WOD. Кроме того статистика помогла мне сконцентрироваться на гимнастических движениях, которые я всегда избегал. Что сказать, гимнастика прогрессирует каждый день!`,
  'navbar.athlete': 'Атлет',
  'navbar.coach': 'Тренер',
  'navbar.blog': 'Блог',
  'footer.title': 'Будь в числе первых, кто получит доступ к HeroSnatch!',
  'footer.privacy': 'Политика конфиденциальности',
  'footer.terms': 'Условия эксплуатации',
  'testimonials.title': 'Что о нас говорят?',
  'testimonials.author1': 'Святослав Смольник',
  'testimonials.author2': 'Маша Голуб',
  'testimonials.author3': 'Олег Мороз',
  'testimonials.role1': 'Соревнующийся Атлет',
  'testimonials.role2': 'Капитан Adidas Runners Kyiv',
  'testimonials.role3': 'UX Architect в Digicode',
  'testimonials.master': 'Мастер спорта по всем видам спорта',
  'feedback.title': 'Будь одним из первых атлетов!',
  'feedback.subtitle': 'Мы отправим вам приглашение на регистрацию как только запустимся',
  'feedback.btn': 'Отправить',
  'feedback.thanks': 'Спасибо, совсем скоро мы отправим вам ссылку на регистрацию!',
  'feedback.textarea': 'Поделитесь своим мнением :)',
  'blog.title': 'Блог',
  'blog.subtitle': 'Последние новости индустрии',
  'blog.news': 'Новости',
  'blog.events': 'События',
  'blog.nutrition': 'Питание',
  'blog.training': 'Тренировки',
  'blog.date': '6 апр 2018',
  'blog.title1': 'Тренажерка или кроссфит?',
  'blog.title2': 'Как правильно питаться тем, кто занимается кроссфитом',
  'blog.content1':
    'Невероятный опыт, пользуюсь неделю – как буд-то пересел с самоката на Теслу. Очень понравилось удобство ведения статистики',
  'blog.content2': 'Невероятный опыт, пользуюсь неделю – как буд-то пересел с самоката на Теслу.',
  'article.title': 'HeroSnatch в жизни',
  'article.date': '06 апр 2018',
  'article.share': 'Поделиться:',
  'article.category': 'Категории',
  'article.articles': 'Другие статьи',
  'pricing.title': 'ЦЕНЫ',
  'pricing.basic': 'БАЗОВЫЙ',
  'pricing.athlete': 'АТЛЕТ',
  'pricing.coach': 'ТРЕНЕР',
  'pricing.perMonth': 'В месяц',
  'pricing.perAthleteMonth': 'за атлета / месяц',
  'pricing.free': 'БЕСПЛАТНО',
  'pricing.getStarted': 'НАЧАТЬ',
  'pricing.contactUs': 'Открыть Чат',
  'pricing.basicFor': 'Бесплатно Всегда!',
  'pricing.athleteFor': 'Для мотивированых людей и Атлетов',
  'pricing.coachFor': 'Для тренеров и залов',
  'pricing.priceBasic': 'БЕСПЛАТНО',
  'pricing.priceAthlete': '4.99$',
  'pricing.priceCoach': '1.5$',
  'pricing.basicDescription':
    "No more excuses, it's time to start tracking! A basic Kilomodo account provides you with the essentials to start tracking workouts and follow along with other athletes at a gym or on a team.",
  'pricing.athleteDescription':
    "No more excuses, it's time to start tracking! A basic Kilomodo account provides you with the essentials to start tracking workouts and follow along with other athletes at a gym or on a team.",
  'pricing.coachDescription':
    "No more excuses, it's time to start tracking! A basic Kilomodo account provides you with the essentials to start tracking workouts and follow along with other athletes at a gym or on a team.",
  'pricing.feature.unlimited': 'Безлим.',
  'pricing.feature.unlimitedAccess': 'Unlimited Access',
  'pricing.feature.limitedAccess': 'Ограниченный Доступ',
  'pricing.feature.unlimitedHistory': 'Неограниченная История',
  'pricing.feature.weeksHistory': 'Недели Истории',
  'pricing.feature.workoutsTracking': 'Учет Тренировок',
  'pricing.feature.workoutsTrackingTooltip': 'Workouts Tracking Tooltip',
  'pricing.feature.workoutLibrary': 'База данных Тренировок',
  'pricing.feature.workoutLibraryTooltip': 'Workout Library Tooltip',
  'pricing.feature.workoutBuilder': 'Workout Builder',
  'pricing.feature.weightCalculator': 'Weight Calculator',
  'pricing.feature.weeklyReporting': 'Weekly Reporting',
  'pricing.feature.personalRecords': 'Таблица Рекордов',
  'pricing.feature.personalWorkoutLibrary': 'Personal Workout Library',
  'pricing.feature.personalWorkoutLibraryTooltip': 'Personal Workout Library Tooltip',
  'pricing.feature.activityFeed': 'Community Activity Feed',
  'pricing.feature.activityFeedTooltip': 'Community Activity Feed Tooltip',
  'pricing.feature.publicWorkouts': 'Публичные Схемы (WOD)',
  'pricing.feature.publicWorkoutsTooltip': 'Public Workouts Tooltip',
  'pricing.feature.privateWorkouts': 'Приватные Схемы',
  'pricing.feature.privateScoring': 'Скрытые Результаты',
  'pricing.feature.privateScoringTooltip': 'Private Scoring Tooltip',
  'pricing.feature.resultAnalysis': 'Results Analysis & Stats',
  'pricing.feature.resultAnalysisTooltip': 'Results Analysis & Stats Tooltip',
  'pricing.feature.resultAnalysisBasic': '30 Дней',
  'pricing.feature.elitePrograms': 'Elite Pre-defined Programs to follow',
  'pricing.feature.programTracks': 'Program Tracks',
  'pricing.feature.programTracksTooltip': 'Program Tracks Tooltip',
  'pricing.feature.programTracksAthlete': '1 Private Track',
  'pricing.feature.programTracksCoach': '5 Программ',
  'pricing.feature.programBuilder': 'Конструктор Програм',
  'pricing.feature.membershipManagement': 'Управление группами атлетов',
  'pricing.feature.membershipManagementTooltip': 'Membership Management Tooltip',
  'pricing.feature.memberNotifications': 'Уведомления атлетов',
  'pricing.feature.allFromPlanBasic': 'Все функции из пакета Базовый +',
  'pricing.feature.allFromPlanAthlete': 'Все функции из пакета Атлет +'
};
