import { NEWS } from '../../constants/blog';
import { StaticQuery, graphql } from 'gatsby';

import BlogItem from './BlogItem';
import Footer from '../Footer';
import { FormattedMessage } from 'react-intl';
import Navbar from '../Navbar';
import React from 'react';
import background from '../../img/bg.jpg';
import cx from 'classnames';
import moment from 'moment';
import styles from './index.module.sass';

class Blog extends React.Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    const category = state && state.category ? state.category : NEWS;

    this.state = { category };
  }

  handleCategorySelection = e => {
    this.setState({
      category: e.currentTarget.dataset.category
    });
  };

  render() {
    const { data } = this.props;
    return (
      <section className="blog">
        <div className="blogHeader" style={{ backgroundImage: 'url(' + background + ')' }}>
          <div className="container blogOuter">
            <Navbar path="blog" />
            <div className="blogTitleOuter">
              <h1 className="blogTitle">
                <FormattedMessage id="blog.title" />
              </h1>
              <h4 className="blogSubtitle">
                <FormattedMessage id="blog.subtitle" />
              </h4>
            </div>
            <div />
          </div>
        </div>
        <div className={styles.blogContainer}>
          <ul className={styles.blogCategory}>
            {data.allMarkdownRemark.edges.map(categories => (
              <li
                key={categories.node.frontmatter.category}
                className={
                  this.state.category === categories.node.frontmatter.category
                    ? cx(styles.blogCategoryItem, 'blog-active-tab')
                    : styles.blogCategoryItem
                }
                onClick={this.handleCategorySelection}
                data-category={categories.node.frontmatter.category}
              >
                <FormattedMessage id={`blog.${categories.node.frontmatter.category}`} />
              </li>
            ))}
          </ul>
          <div className={styles.blogList}>
            {data.allMarkdownRemark.edges
              .filter(post => post.node.frontmatter.category === this.state.category)
              .map(post => (
                <BlogItem
                  key={post.node.id}
                  title={post.node.frontmatter.title}
                  content={`${post.node.excerpt}...`}
                  path={post.node.frontmatter.path}
                  coverImage={post.node.frontmatter.coverImage}
                  date={moment(post.node.frontmatter.date).format('ll')}
                />
              ))}
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}

Blog.propTypes = {};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter {
                path
                title
                author
                date
                category
                coverImage
              }
              excerpt(pruneLength: 100)
            }
          }
        }
      }
    `}
    render={data => <Blog data={data} {...props} />}
  />
);
